import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Pagination from 'react-bootstrap/Pagination'
import Layout from '../../components/layout'
import PostSummary from '../../components/blog/PostSummary'

class BlogIndex extends React.Component {
  renderPagination(currentPage, numPages) {

    if (numPages === 1) {
      return
    }

    let i = 1
    const pages = []
    while (i <= numPages) {
      pages.push(i)
      i++
    }

    return pages.map((page) => {
      const path = `/blog/${page > 1 ? page : ''}`
      let item = [];

      if (numPages > 6) {
        if (page === currentPage || page === currentPage + 1 || page === currentPage - 1) {
          item.push(
            <Pagination.Item href={path} active={page === currentPage}>
              {page}
            </Pagination.Item>
          )
        } else if (page === 1) {
          item.push(<Pagination.First href={'/blog/'} />)
          item.push(<Pagination.Ellipsis href={`/blog/${currentPage - 3 <= 1 ? '' : currentPage - 3}`}/>)
        } else if (page === numPages) {
          item.push(<Pagination.Ellipsis href={`/blog/${currentPage + 3 >= numPages ? numPages : currentPage + 3}`}/>)
          item.push(<Pagination.Last href={`/blog/${numPages}`}/>)
        }

      } else {
        item.push(
          <Pagination.Item href={path} active={page === currentPage}>
            {page}
          </Pagination.Item>
        )
      }

      return item;
    })
  }

  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages, tags } = this.props.pageContext

    return (
      <Layout
        title={`blog`}
        location={this.props.location}
        keywords={tags}
      >
        <Container>
          <Row>
            {posts.map(({ node }, i) => {
              return <Col key={i} sm={12} className="mb-4">
                <PostSummary post={node} />
                <hr className="mt-4"/>
              </Col>
            })}
          </Row>
          <Row>
            <Col>
              <Pagination>
                {this.renderPagination(currentPage, numPages)}
              </Pagination>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/(pages\/blog)/" }
        frontmatter: {
          isPublished: {
           eq: true
          }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            subtitle
            image
          }
        }
      }
    }
  }
`
